import { Component, HostListener } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormService } from '../../shared/services/form.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [
    RouterModule
  ]
})
export class NavComponent {

  lastScrollPos = window.pageYOffset;
  menuButtonCheck: HTMLInputElement;

  constructor(private formService: FormService, public router: Router) {
    this.menuButtonCheck = document.querySelector(".menu-btn") as HTMLInputElement;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    let currentScrollPos = window.pageYOffset;
    if (this.lastScrollPos > currentScrollPos) {
      document.getElementById("top-nav")!.style.top = "0";
    } else {
      document.getElementById("top-nav")!.style.top = "-110px";
    }
    this.lastScrollPos = currentScrollPos;
    if (this.menuButtonCheck) {
      this.menuButtonCheck.checked = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  scrollToBottom(containerId?: string) {
    console.log('scrollToBottom', containerId);
    if (containerId) {
      // Scroll a specific container
      const container = document.getElementById(containerId);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.warn(`Container with id ${containerId} not found.`);
      }
    } else {
      // Scroll the entire document
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }

  preregister(type: string) {
    this.router.navigate([`/form-${type}`]);
  }
  login(type: string) {
    this.router.navigate([`/form-${type}`]);
  }
}
