<nav class="top-nav" id="top-nav"
  [class.transparent]="router.url.includes('form') || router.url.includes('explore')">
  <a href="#" class="logo"><img src="assets/images/white logo with tbg.png" alt="Your Logo Here"></a>  
  <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>  
  <ul class="menu">       
    <li class="list-item"><a class="list-item-link" [routerLink]="['/services']" (click)="scrollToTop()"
        [routerLinkActive]="'is-active'">Services</a></li>
    <li class="list-item"><a class="list-item-link" [routerLink]="['/press-release']" (click)="scrollToTop()"
        [routerLinkActive]="'is-active'">Press Release</a></li>
    <li class="list-item"><a class="list-item-link" (click)="scrollToBottom('footer-container')"
        [routerLinkActive]="'is-active'">Contact</a></li>
    <li class="list-item"><a class="list-item-link" [routerLink]="['/login']" (click)="scrollToTop()" [routerLinkActive]="'is-active'">Login</a></li>
  </ul>
</nav>